import { Anchor, Container, Divider, SimpleGrid, Stack, Text } from '@mantine/core';
import classes from './Footer.module.css';

export function Footer(): JSX.Element {
  return (
    <footer className={classes.footer}>
      <div className={classes.inner}>
        <Container p="xl">
          <Stack gap="xl">
            <Text c="dimmed" size="sm">
              &copy; {new Date().getFullYear()} Ramona. All rights reserved.
            </Text>
          </Stack>
        </Container>
      </div>
    </footer>
  );
}
