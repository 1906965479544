import { Card, Text, Button, Image } from '@mantine/core';
import { getConfig } from '../../config';

interface VisitInviteProps {
    ptName: string;
  }

 export const VisitInvite: React.FC<VisitInviteProps> = ({ ptName }) => {
    const config = getConfig();
    const handleBookVisit = () => {
      window.location.href = config.bookingUrl || "mailto:support@ramona.care?Schedule%20New%20Patient%20Visit&body=I%20just%20completed%20my%20intake%20form%20and%20am%20ready%20to%20talk%20with%20a%20Ramona%20clinician.";
    };
  
  return (
    <Card shadow="sm" padding="lg" style={{ maxWidth: 340, margin: 'auto' }}>
      <Card.Section>
        <Image src="https://via.placeholder.com/340x160" alt="Doctor visit" />
      </Card.Section>

      <Text  size="lg" style={{ marginTop: 10 }}>
        Book Your Doctor Visit, {ptName}!
      </Text>

      <Text size="sm" style={{ marginBottom: 10 }}>
      Phew! We've done it. {ptName}, thank you for sharing your health story with us today. The information you provided will be essential in creating your personalized care plan. Schedule an appointment with a doctor at your convenience. Click the button below to book your visit.
      </Text>

      <Button variant="filled" fullWidth onClick={handleBookVisit}>
        Book Visit
      </Button>
    </Card>
  );
};

export default VisitInvite;