// Default values work on localhost:3000 and foomedical.com
// Replace these values with your own values for production

export interface MedplumAppConfig {
    baseUrl?: string;
    clientId?: string;
    projectId?: string;
    googleClientId?: string;
    recaptchaSiteKey?: string;
    registerEnabled?: boolean | string;
    bookingUrl?: string;
    onboardingId?: string;
    ramonaPhoneNumber?: string;
    matomoTagUrl?: string;
  }
  
  const config: MedplumAppConfig = {
    baseUrl: import.meta.env?.MEDPLUM_BASE_URL,
    clientId: import.meta.env?.MEDPLUM_CLIENT_ID,
    googleClientId: import.meta.env?.GOOGLE_CLIENT_ID,
    recaptchaSiteKey: import.meta.env?.RECAPTCHA_SITE_KEY,
    registerEnabled: import.meta.env?.MEDPLUM_REGISTER_ENABLED,
    projectId: import.meta.env?.MEDPLUM_PROJECT_ID,
    bookingUrl: import.meta.env?.BOOKING_URL,
    onboardingId: import.meta.env?.VITE_ONBOARDING_SURVEY_ID,
    ramonaPhoneNumber: import.meta.env?.VITE_RAMONA_PHONE_NUMBER,
    matomoTagUrl: import.meta.env?.VITE_MATOMO_TAG_URL
  };
  
  export function getConfig(): MedplumAppConfig {
    return config;
  }
  
  export function isRegisterEnabled(): boolean {
    // This try/catch exists to prevent Rollup optimization from removing this function
    try {
      return config.registerEnabled !== false && config.registerEnabled !== 'false';
    } catch {
      return true;
    }
  }
  