import { Button, Group, Stack, Progress, Paper, Text, Grid } from '@mantine/core';
import { QuestionnaireItem, QuestionnaireResponse, QuestionnaireResponseItem } from '@medplum/fhirtypes';
import { QuestionnaireItemType } from '@medplum/react';
import { QuestionnaireRepeatableItem } from '../QuestionnaireFormItem/QuestionnaireRepeatableItem';
import { QuestionnaireRepeatedGroup } from './QuestionnaireFormGroup';

interface QuestionnairePageSequenceProps {
  readonly items: QuestionnaireItem[];
  readonly response?: QuestionnaireResponse;
  readonly activePage: number;
  readonly numberOfPages: number;
  readonly submitButtonText?: string;
  readonly checkForQuestionEnabled: (item: QuestionnaireItem) => boolean;
  readonly onChange: (items: QuestionnaireResponseItem | QuestionnaireResponseItem[]) => void;
  readonly nextStep: () => void;
  readonly prevStep: () => void;
}

export function QuestionnairePageSequence(props: QuestionnairePageSequenceProps): JSX.Element {
  const {
    items,
    response,
    activePage,
    onChange,
    nextStep,
    prevStep,
    numberOfPages,
    submitButtonText,
    checkForQuestionEnabled,
  } = props;

  const progress = ((activePage + 1) / numberOfPages) * 100;

  const form = items.map((item) => {
    const itemResponse = response?.item?.filter((i) => i.linkId === item.linkId) ?? [];

    return item.type === QuestionnaireItemType.group ? (
      <QuestionnaireRepeatedGroup
        key={item.linkId}
        item={item}
        response={itemResponse}
        onChange={onChange}
        checkForQuestionEnabled={checkForQuestionEnabled}
      />
    ) : (
      <QuestionnaireRepeatableItem
        key={item.linkId}
        item={item}
        response={itemResponse?.[0]}
        onChange={onChange}
        checkForQuestionEnabled={checkForQuestionEnabled}
      />
    );
  });

  return (
    <>
      <Stack>{form[activePage]}</Stack>
      <Grid >
      <Grid.Col span={4}>
      <Paper shadow="xs" mt="xl" radius="lg" p="xs" >
      <Text size="sm" mb="0.25rem">{progress.toFixed(0)}% completed</Text>
      <Progress
        value={progress}
        size="sm"
        radius="sm"
        mb="0"
        striped
        styles={{
          label: {
            color: 'white',
            fontWeight: 'bold',
          },
        }}
      >
      </Progress>
      </Paper>
      </Grid.Col>

      <Grid.Col span={8} >
        <Group justify='flex-end'>
      <ButtonGroup
        activePage={activePage}
        numberOfPages={numberOfPages}
        nextStep={nextStep}
        prevStep={prevStep}
        submitButtonText={submitButtonText}
      />
</Group>
      </Grid.Col>
      </Grid>
    </>
  );
}

interface ButtonGroupProps {
  readonly activePage: number;
  readonly numberOfPages: number;
  readonly submitButtonText?: string;
  readonly nextStep: () => void;
  readonly prevStep: () => void;
}

function ButtonGroup(props: ButtonGroupProps): JSX.Element {
  const showBackButton = props.activePage > 0;
  const showNextButton = props.activePage < props.numberOfPages - 1;
  const showSubmitButton = props.activePage === props.numberOfPages - 1;

  return (
    <Group justify="space-between" mt="xl" gap="xs">
      {showBackButton && <Button size="lg" variant="outline" radius="lg" onClick={props.prevStep}>Back</Button>}
      {showNextButton && (
        <Button
          size="lg" 
          radius="lg"
          onClick={(e) => {
            const form = e.currentTarget.closest('form') as HTMLFormElement;
            if (form.reportValidity()) {
              props.nextStep();
            }
          }}
        >
          Next
        </Button>
      )}
      {showSubmitButton && <Button size="lg" radius="lg" type="submit">{props.submitButtonText ?? 'Submit'}</Button>}
    </Group>
  );
}