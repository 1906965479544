import React, { useState, useEffect } from 'react';
import { registerBlockType } from '@quillforms/blocks';
import { useMessages, useBlockTheme } from '@quillforms/renderer-core';
import { css } from 'emotion';
import tinyColor from 'tinycolor2';

interface AddressFields {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
}

const US_STATES = [
  { value: '', label: 'State' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const AddressInput = React.forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
  const theme = useBlockTheme(props.attributes?.themeId);
  const answersColor = tinyColor(theme.answersColor);

  return (
    <input
      {...props}
      ref={ref}
      className={css`
        width: 100%;
        border: none;
        border-radius: 0;
        outline: none;
        padding: 8px 0;
        margin-bottom: 10px;
        background: transparent;
        transition: box-shadow 0.1s ease-out 0s;
        box-shadow: ${answersColor.setAlpha(0.3).toString()} 0px 1px;
        color: ${theme.answersColor};
        -webkit-appearance: none;

        &::placeholder {
          opacity: 0.5;
          color: ${theme.answersColor};
        }

        &:focus {
          box-shadow: ${answersColor.setAlpha(1).toString()} 0px 2px;
        }
      `}
    />
  );
});

const StateSelect = React.forwardRef((props: any, ref: React.Ref<HTMLSelectElement>) => {
  const theme = useBlockTheme(props.attributes?.themeId);
  const answersColor = tinyColor(theme.answersColor);

  return (
    <select
      {...props}
      ref={ref}
      className={css`
        width: 100%;
        border: none;
        border-radius: 0;
        outline: none;
        padding: 8px 0;
        margin-bottom: 10px;
        background: transparent;
        transition: box-shadow 0.1s ease-out 0s;
        box-shadow: ${answersColor.setAlpha(0.3).toString()} 0px 1px;
        color: ${theme.answersColor};
        -webkit-appearance: none;

        &:focus {
          box-shadow: ${answersColor.setAlpha(1).toString()} 0px 2px;
        }
      `}
    >
      {US_STATES.map((state) => (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      ))}
    </select>
  );
});

const USMailingAddress: React.FC<any> = ({
  id,
  attributes,
  setIsValid,
  setIsAnswered,
  setValidationErr,
  showNextBtn,
  blockWithError,
  val,
  setVal
}) => {
  const [address, setAddress] = useState<AddressFields>(val || {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: ''
  });
  const { required } = attributes;
  const messages = useMessages();
  const theme = useBlockTheme(attributes.themeId);

  useEffect(() => {
    const isAddressComplete = address.street1 && address.city && address.state && address.zipCode;
    if (isAddressComplete) {
      setIsAnswered(true);
      showNextBtn(true);
      setIsValid(true);
      setValidationErr(null);
    } else {
      setIsAnswered(false);
      showNextBtn(false);
      if (required) {
        setIsValid(false);
        setValidationErr("Please fill in all required address fields.");
      }
    }
  }, [address, required, setIsAnswered, setIsValid, setValidationErr, showNextBtn]);

  const handleInputChange = (field: keyof AddressFields) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newAddress = { ...address, [field]: e.target.value };
    setAddress(newAddress);
    setVal(newAddress);
  };

  return (
    <div className="us-mailing-address-block">
      <AddressInput
        type="text"
        value={address.street1}
        onChange={handleInputChange('street1')}
        placeholder="Street Address"
        attributes={attributes}
      />
      <AddressInput
        type="text"
        value={address.street2}
        onChange={handleInputChange('street2')}
        placeholder="Apt, Suite, Unit, etc."
        attributes={attributes}
      />
      <AddressInput
        type="text"
        value={address.city}
        onChange={handleInputChange('city')}
        placeholder="City"
        attributes={attributes}
      />
      <div className={css`
        display: flex;
        justify-content: space-between;
      `}>
        <div style={{ width: '48%' }}>
          <StateSelect
            value={address.state}
            onChange={handleInputChange('state')}
            attributes={attributes}
          />
        </div>
        <AddressInput
          type="text"
          value={address.zipCode}
          onChange={handleInputChange('zipCode')}
          placeholder="ZIP Code"
          attributes={attributes}
          style={{ width: '48%' }}
        />
      </div>
    </div>
  );
};

registerBlockType("us_mailing_address_block", {
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
      default: true
    },
  },
  display: USMailingAddress
});