import React, { useCallback, useEffect, useState } from 'react';
import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import type { BlockAttributes } from '@quillforms/types';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '@quillforms/renderer-core/build-style/style.css';
import { createReference } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '@/components/CustomRegisterForm';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import '../../blocks/phoneblock';
import '../../blocks/USMailingAddress';
import { parseQuillFormAnswers } from '../../blocks/ParseQuillFormAnswers';
import classes from '../TestFormPage.module.css';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
import { getConfig } from '../../config';
import { useNavigate, useSearchParams } from 'react-router-dom';

import heroImage from '@/img/profile/ramona-hero.webp';
import startImage from '@/img/profile/older-man-headphones-track.webp';
import comparisonImage from '@/img/profile/ramona-wellness-coaching-comparison.webp';
import { useMatomo } from '@/common/useMatomo';

import { Box, Grid } from '@mantine/core';


interface SubmissionDispatchers {
  completeForm: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSubmissionErr: (error: string) => void;
}

type Choice = {
  value: string;
  label: string;
};

type BeforeGoingNextParams = {
  setIsFieldValid: (id: string, flag: boolean) => void;
  setFieldValidationErr: (id: string, err: string) => void;
  setIsPending: (flag: boolean) => void;
  setIsCurrentBlockSafeToSwipe: (flag: boolean) => void;
  goToBlock: (id: string) => void;
  goNext: () => void;
  currentBlockId: string;
  answers: Record<string, any>;
};

function AccountCreationForm({
  onAccountCreated,
}: {
  onAccountCreated: () => void;
}): JSX.Element {
  const [error, setError] = useState('');
  const medplum = useMedplum();
  const config = getConfig();

  const handleRegister = () => {
    onAccountCreated();  
  };

  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => handleRegister()}
          >
            <h3>Let's Create Your Account</h3>
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}

export function HealthyHeartSurveyPage(): JSX.Element {
  const medplum = useMedplum();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { trackFormEvent } = useMatomo();

  const [surveyData, setSurveyData] = useState<Record<string, any> | null>(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const primaryMotivation = useFieldAnswer('primary_motivation') as string | undefined;
  const motivationLevel = useFieldAnswer('motivation_level') as number | undefined;
  const monitoringFrequency = useFieldAnswer('monitoring_frequency') as string | undefined;
  const medicationStatus = useFieldAnswer('medication_status') as string[] | undefined;
  const medicationAdherence = useFieldAnswer('medication_adherence') as string[] | undefined;
  const healthConditions = useFieldAnswer('health_conditions') as string[] | undefined;
  const focusArea = useFieldAnswer('focus_area') as string[] | undefined;
  const currentDiet = useFieldAnswer('current_diet') as string[] | undefined;
  const nextStep = useFieldAnswer('next_step') as string[] | undefined;
  const potentialObstacles = useFieldAnswer('potential_obstacles') as string[] | undefined;

  useEffect(() => {
    if (queryString && medplum.getProfile()) {
      const patient = medplum.getProfile() as Patient;
      const updatedPatient = {
        ...patient,
        extension: [
          ...(patient.extension || []),
          {
            url: 'http://ramona.care/fhir/StructureDefinition/query-string',
            valueString: queryString,
          },
        ],
      };
      medplum
        .updateResource(updatedPatient)
        .then(() => {
          console.log('Patient resource updated successfully');
        })
        .catch((error) => {
          console.error('Error updating patient resource:', error);
        });
    }
  }, [queryString, medplum]);

  const handleSubmit = useCallback(
    (data: Record<string, any>, dispatchers: SubmissionDispatchers) => {
      const { completeForm, setIsSubmitting, setSubmissionErr } = dispatchers;

      console.log('Submitted answers:', data);

      const profile = medplum.getProfile();

      if (profile) {
        const patientReference = createReference(profile as Patient);
        const itemList = parseQuillFormAnswers(data.answers.answers.answers, formObj.blocks);
        const newResponse: QuestionnaireResponse = {
          resourceType: 'QuestionnaireResponse',
          status: 'completed',
          item: itemList,
          questionnaire: `Questionnaire/${config.onboardingId}`,
          subject: patientReference,
          source: patientReference,
          authored: new Date().toISOString(),
          author: patientReference,
        };

        setIsSubmitting(true);
        medplum
          .createResource(newResponse)
          .then((response) => {
            console.log('Created QuestionnaireResponse:', response);
            setIsSubmitting(false);
            completeForm();
            navigate('/select-plan');
          })
          .catch((error) => {
            console.error('Error creating QuestionnaireResponse:', error);
            setSubmissionErr('An error occurred while submitting the form.');
            setIsSubmitting(false);
          });
      } else {
        setSurveyData(data);
        setIsCreatingAccount(true);
      }
    },
    [medplum, config.onboardingId, navigate]
  );

  const handleAccountCreated = useCallback(() => {
    const profile = medplum.getProfile() as Patient;
    if (profile && surveyData) {
      const patientReference = createReference(profile);
      const itemList = parseQuillFormAnswers(surveyData.answers, formObj.blocks);
      itemList.push({
        linkId: "primary_goal",
        text: "What's your primary health goal right now?",
        answer: [
          {
            valueString: "Manage heart health"
          }
        ]
      });
      const newResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        item: itemList,
        questionnaire: `Questionnaire/${config.onboardingId}`,
        subject: patientReference,
        source: patientReference,
        authored: new Date().toISOString(),
        author: patientReference,
      };

      medplum
        .createResource(newResponse)
        .then((response) => {
          console.log('Created QuestionnaireResponse:', response);
          setIsCreatingAccount(false);
          navigate('/select-plan');
        })
        .catch((error) => {
          console.error('Error creating QuestionnaireResponse:', error);
        });
    }
  }, [medplum, surveyData, config.onboardingId, navigate]);

  const beforeGoingNext = ({
    setIsFieldValid,
    setFieldValidationErr,
    setIsPending,
    setIsCurrentBlockSafeToSwipe,
    goToBlock,
    goNext,
    currentBlockId,
    answers,
  }: BeforeGoingNextParams): void => {

    // Track the new block view
    const currentBlock = formObj.blocks.find(block => block.id === currentBlockId);
    if (currentBlock) {
      trackFormEvent(
        'Form Navigation',
        'View Block',
        `${currentBlock.name} - ${currentBlockId}`,
        answers[currentBlockId]?.value || 'null-value'
      );
    }

    const setFieldValid = (): void => {
      setIsFieldValid(currentBlockId, true);
      setFieldValidationErr(currentBlockId, '');
      setIsCurrentBlockSafeToSwipe(true);
    };

    const setFieldInvalid = (errorMessage: string): void => {
      setIsFieldValid(currentBlockId, false);
      setFieldValidationErr(currentBlockId, errorMessage);
      setIsCurrentBlockSafeToSwipe(false);
    };

    switch (currentBlockId) {
      case 'age':
        const age = answers[currentBlockId].value as number;
        if (age < 18) {
          setFieldInvalid('You must be 18 or older to use this service.');
        } else {
          setFieldValid();
          goNext();
        }
        break;

      default:
        setFieldValid();
        goNext();
    }
  };

  const formObj: FormObj = {
    blocks: [
        {
            name: 'welcome-screen',
            id: 'welcome',
            attributes: {
              label: 'Welcome to Your Blood Pressure Management Journey!',
              description: "Hi there! I'm Ramona, your AI wellness coach. I'm excited to help you manage your blood pressure naturally! This survey will help me understand your situation and create a personalized plan. It should take about 5 minutes to complete.",
              buttonText: "Let's Get Started",
              attachment: {
                type: 'image',
                url: heroImage,
              },
              layout: 'float-right',
            },
          },
      {
        name: 'short-text',
        id: 'preferred_name',
        attributes: {
          label: "What's your preferred name?",
          required: true,
        },
      },
      {
        name: 'multiple-choice',
        id: 'primary_motivation',
        attributes: {
          label: "What's your main reason for wanting to manage your blood pressure?",
          description: "Understanding your motivation helps us create a meaningful plan together.",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'reduce_meds', label: "To reduce dependence on medications (with doctor's guidance)" },
            { value: 'prevent_meds', label: 'To prevent need for medications' },
            { value: 'support_treatment', label: 'To support existing medical treatment' },
            { value: 'improve_health', label: 'To improve overall cardiovascular health' },
            { value: 'other', label: 'Other' },
          ],
        },
      },
      ...(primaryMotivation?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_primary_motivation',
              attributes: {
                label: 'Please specify your other primary motivation:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'intro_value',
          attributes: {
            label: "With Ramona, you take your first personalized step towards heart healthy living on day one.",
            description: "Personalized digital health interventions significantly outperform non-personalized approaches in improving physical activity and diet*\n\n<i>*Journal Medical Internet Research, 2021</i>",
            layout: 'float-right',
            attachment: {
              type: 'image',
              url: comparisonImage
            }
          },
        },
      {
        name: 'slider',
        id: 'motivation_level',
        attributes: {
          label: 'On a scale of 1-10, how motivated are you to manage your blood pressure naturally?',
          description: '1: Not at all, 10: Extremely',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'statement',
        id: 'goal_specific_questions',
        attributes: {
          label:
            motivationLevel === undefined || motivationLevel < 5
              ? "We're Just Getting Started, {{field:preferred_name}}!"
              : 'Great Start, {{field:preferred_name}}!',
          description:
            motivationLevel === undefined || motivationLevel < 5
              ? "While you may not have the motivation now, making progress is possible. No matter where you're starting from we can define a first step that you can achieve in days."
              : "I'm excited to help figure out your first goal. Let's continue to define a first step that you can achieve in days.",
          attachment: {
            type: 'image',
            url: startImage,
          },
          layout: 'split-right',
        },
      },
      {
        name: 'multiple-choice',
        id: 'monitoring_frequency',
        attributes: {
          label: 'Tell me about your blood pressure monitoring:',
          description: 'Regular monitoring helps us track progress and adjust strategies as needed.',
          verticalAlign: true,
          required: true,
          choices: [
            { value: 'daily', label: 'I monitor daily' },
            { value: 'weekly', label: 'I monitor weekly' },
            { value: 'occasional', label: 'I monitor occasionally' },
            { value: 'plan_to_start', label: "I don't monitor yet but plan to start" },
            { value: 'dont_monitor', label: "I don't monitor my blood pressure" },
          ],
        },
      },
      ...( !(monitoringFrequency?.includes('dont_monitor') || monitoringFrequency?.includes('plan_to_start')) ? [
        {
            name: 'group',
            id: 'morning_blood_pressure',
            attributes: {
              label: "What are your typical <strong>morning</strong> blood pressure readings?",
              description: 'For example: 120 (systolic) over 80 (diastolic).\n\nLeave blank if you\'re not sure - we can start tracking from here!',
            },
            innerBlocks: [
              {
                name: 'number',
                id: 'morning_blood_pressure_systolic',
                attributes: {
                  required: false,
                  label: 'Systolic (Top Number)',
                  setMin: true,
                  min: 30,
                  setMax: true,
                  max: 350,
                },
              },
              {
                name: 'number',
                id: 'morning_blood_pressure_diastolic',
                attributes: {
                  required: false,
                  label: 'Diastolic (Bottom Number)',
                  setMin: true,
                  min: 0,
                  setMax: true,
                  max: 200,
                },
              },
            ],
          },
          {
            name: 'group',
            id: 'evening_blood_pressure',
            attributes: {
              label: "What are your typical <strong>evening</strong> blood pressure readings?",
              description: 'For example: 120 (systolic) over 80 (diastolic).\n\nAgain, this is something we can start tracking from here!',
              required: false,
            },
            innerBlocks: [
              {
                name: 'number',
                id: 'evening_blood_pressure_systolic',
                attributes: {
                  required: false,
                  label: 'Systolic (Top Number)',
                  setMin: true,
                  min: 30,
                  setMax: true,
                  max: 350,
                },
              },
              {
                name: 'number',
                id: 'evening_blood_pressure_diastolic',
                attributes: {
                  required: false,
                  label: 'Diastolic (Bottom Number)',
                  setMin: true,
                  min: 0,
                  setMax: true,
                  max: 200,
                },
              },
            ],
          },
        ] : []),
      {
        name: 'multiple-choice',
        id: 'diagnosis_status',
        attributes: {
          label: 'Have you been diagnosed with high blood pressure?',
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'recent', label: 'Yes, within the last year' },
            { value: 'past', label: 'Yes, more than a year ago' },
            { value: 'borderline', label: 'No, but I have borderline high blood pressure' },
            { value: 'prevention', label: "No, I'm focusing on prevention" },
          ],
        },
      },
      {
        name: 'multiple-choice',
        id: 'medication_status',
        attributes: {
          label: 'Are you taking any blood pressure medications?',
          description: 'This helps us safely coordinate natural approaches with your medical treatment.',
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
        },
      },
      ...(medicationStatus?.includes('yes') ? [{
        name: 'group',
        id: 'medication_group',
        attributes: {
            label: 'Please list your medications.',
            description: 'Include name and dosage when possible.'
        },
        innerBlocks: [
            {
                name: 'short-text',
                id: 'medication_1',
                attributes: {
                label: 'Medication 1:',
                required: false,
                },
          },
          {
            name: 'short-text',
            id: 'medication_2',
            attributes: {
            label: 'Medication 2:',
            required: false,
            },
      },{
        name: 'short-text',
        id: 'medication_3',
        attributes: {
        label: 'Medication 3:',
        required: false,
        },
  },{
    name: 'short-text',
    id: 'medication_4',
    attributes: {
    label: 'Medication 4:',
    required: false,
    },
}
    ]}
    ]
      :[]),
      {
        name: 'statement',
        id: 'vitals_intro',
        attributes: {
          label: "Building the Foundation for Your Plan",
          description: "Next, let's get some basic details."
        },
      },
      {
        name: 'group',
        id: 'height',
        attributes: {
          label: "What's your height? (in feet/inches)",
          description: 'Height is used to calculate health metrics and personalize fitness recommendations.',
        },
        innerBlocks: [
          {
            name: 'number',
            id: 'height-feet',
            attributes: {
              required: true,
              label: 'Feet',
              setMin: true,
              min: 3,
              setMax: true,
              max: 8,
            },
          },
          {
            name: 'number',
            id: 'height-inches',
            attributes: {
              required: true,
              label: 'Inches',
              setMin: true,
              min: 0,
              setMax: true,
              max: 11,
            },
          },
        ],
      },
      {
        name: 'number',
        id: 'weight',
        attributes: {
          label: "What's your current weight? (in pounds)",
          description: 'Weight helps us track progress and customize nutrition and exercise plans to your needs.',
          required: true,
          setMin: true,
          min: 50,
          setMax: true,
          max: 1000,
        },
      },
      {
        name: 'multiple-choice',
        id: 'health_conditions',
        attributes: {
          label: 'Do you have any of these conditions?',
          description: 'This helps us create a safe, effective plan for you.',
          multiple: true,
          verticalAlign: true,
          required: true,
          choices: [
            { value: 'heart_disease', label: 'Heart disease' },
            { value: 'diabetes', label: 'Diabetes' },
            { value: 'kidney_disease', label: 'Kidney disease' },
            { value: 'high_cholesterol', label: 'High cholesterol' },
            { value: 'sleep_apnea', label: 'Sleep apnea' },
            { value: 'thyroid', label: 'Thyroid problems' },
            { value: 'other', label: 'Other important condition' },
            { value: 'none', label: 'None of these' },
          ],
        },
      },
      ...(healthConditions?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_health_condition',
              attributes: {
                label: 'Please specify your other health condition:',
                required: true,
              },
            },
          ]
        : []),
        {
            name: 'statement',
            id: 'not_replacing_your_doctor',
            attributes: {
              label: "Important Note",
              description: "This program supports, but doesn't replace, your medical care.\n\n&nbsp;\n\nPlease continue working with your healthcare provider and seek immediate medical attention for concerning symptoms (e.g., severe headache, chest pain, difficulty breathing).",
              buttonText: "I Understand"
          },
          },
        {
            name: 'multiple-choice',
            id: 'family_history',
            attributes: {
              label: 'Does your immediate family have a history of:',
              multiple: true,
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'high_bp', label: 'High blood pressure' },
                { value: 'heart_disease', label: 'Heart disease' },
                { value: 'stroke', label: 'Stroke' },
                { value: 'none', label: 'None of these' },
                { value: 'unsure', label: 'Not sure' },
              ],
            },
          },
        {
          name: 'statement',
          id: 'lifestyle_intro',
          attributes: {
            label: "Let's Look at Your Current Habits",
            description: "Understanding your routine helps us build on your strengths and find achievable next steps.",
        },
        },
        {
            name: 'multiple-choice',
            id: 'cooking_frequency',
            attributes: {
              label: 'How often do you cook meals at home?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'salt_usage',
            attributes: {
              label: 'How often do you add salt while cooking or at the table?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'processed_foods',
            attributes: {
              label: 'How often do you eat processed or packaged foods?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'alcohol_consumption',
            attributes: {
              label: 'How often do you consume alcoholic beverages?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ] as Choice[],
            },
          },
          {
            name: 'multiple-choice',
            id: 'physical_activity',
            attributes: {
              label: 'How often do you get physical activity (walking, exercise, etc.)?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'sleep_quality',
            attributes: {
              label: 'How often do you get 7-8 hours of sleep?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'stress_frequency',
            attributes: {
              label: 'How often do you take time to relax or unwind?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'medication_adherence',
            attributes: {
              label: 'How often do you take medication <strong>as prescribed</strong>',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
                { value: 'no_medications', label: 'I have no prescriptions'}
              ],
            },
          },
      {
        name: 'multiple-choice',
        id: 'communication_style',
        attributes: {
          label: 'How would you like me to communicate with you?',
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'motivational', label: 'Motivational and encouraging' },
            { value: 'factual', label: 'Factual and informative' },
            { value: 'nurturing', label: 'Nurturing and supportive' },
            { value: 'direct', label: 'Direct and to-the-point' },
            { value: 'mix', label: 'Mix of styles' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'focus_area',
        attributes: {
          label: 'Which area would you like to work on first?',
          description: "We'll focus on what feels most meaningful and doable for you.",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'eating', label: 'Healthier eating habits' },
            { value: 'activity', label: 'Regular physical activity' },
            { value: 'sleep', label: 'Better sleep routine' },
            { value: 'stress', label: 'Stress management' },
            { value: 'monitoring', label: 'Blood pressure monitoring' },
            ...((medicationStatus?.includes('yes') || !medicationAdherence?.includes('no_medication'))?[
                { value: 'medication', label: 'Medication consistency' }
            ]:[])
            ,
            { value: 'alcohol', label: 'Reducing alcohol' },
            { value: 'other', label: 'Other' },
          ],
        },

      },
      ...(focusArea?.includes('other') ? [{
        name: 'short-text',
        id: 'next_step',
        attributes: {
          label: "What is one small step for your heart health can you commit to this week?",
          description: "Starting with something achievable builds confidence and momentum!",
          required: true,}
        }] 
      : [{
        name: 'multiple-choice',
        id: 'next_step',
        attributes: {
          label: "👍 {{field:focus_area}} is a great area to focus on!\n\nWhich one small step for your heart health can you commit to this week?",
          description: "Starting with something achievable builds confidence and momentum!",
          required: true,
          verticalAlign: true,
        choices: 
        focusArea?.includes('eating')
        ? [

            { value: 'replace_snack', label: 'Replace one processed snack with fruit or vegetables' },
            { value: 'read_labels', label: 'Read nutrition labels when grocery shopping this week' },
            { value: 'cook_low_sodium', label: 'Cook one low-sodium meal at home' },
            { value: 'measure_portions', label: 'Measure portion sizes for one meal each day' },
                    { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('activity')
        ? [
            { value: 'walk_after_meal', label: 'Take a 10-minute walk after one meal each day' },
            { value: 'morning_stretch', label: 'Do 5 minutes of stretching each morning' },
            { value: 'park_farther', label: 'Park farther from entrances to add more steps' },
            { value: 'take_stairs', label: 'Take the stairs instead of elevator once daily' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('sleep')
        ? [
            { value: 'consistent_bedtime', label: 'Set a consistent bedtime for this week' },
            { value: 'wind_down_routine', label: 'Create a 5-minute evening wind-down routine' },
            { value: 'devices_away', label: 'Put devices away 30 minutes before bed' },
            { value: 'bedroom_environment', label: 'Make your bedroom darker or quieter' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('stress')
        ? [
            { value: 'deep_breaths', label: 'Try 3 deep breaths when feeling stressed' },
            { value: 'workday_break', label: 'Take one 5-minute break during your workday' },
            { value: 'gratitude_practice', label: 'Start a simple gratitude practice' },
            { value: 'calming_music', label: 'Listen to calming music during one daily task' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('monitoring')
        ? [
            { value: 'morning_bp_check', label: 'Check BP once each morning this week' },
            { value: 'bp_log', label: 'Record BP readings in a simple log' },
            { value: 'learn_bp_technique', label: 'Learn how to properly position arm for BP reading' },
            { value: 'quiet_before_bp', label: 'Take 3 quiet minutes before checking BP' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('medication')
        ? [
            { value: 'med_reminder', label: 'Set one daily medication reminder' },
            { value: 'pill_organizer', label: 'Use a pill organizer for the week' },
            { value: 'visible_meds', label: 'Keep medications visible in your daily routine' },
            { value: 'med_log', label: 'Track medications in a simple log' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('alcohol')
        ? [
            { value: 'replace_drink', label: 'Replace one alcoholic drink with sparkling water' },
            { value: 'alcohol_free_days', label: 'Plan two alcohol-free days this week' },
            { value: 'measure_drinks', label: 'Measure drink portions to stay aware' },
            { value: 'non_drinking_stress_relief', label: 'Identify one non-drinking stress relief activity' },
            { value: 'other', label: 'Other' },
            ]:[]
    }
}]),
      
      ...(nextStep?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'next_step_other',
              attributes: {
                label: "Please specify the other small step you'd like to take:",
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'multiple-choice',
        id: 'potential_obstacles',
        attributes: {
          label: 'What might get in the way of you taking this step?',
          description: 'Choose all that apply',
          multiple: true,
          verticalAlign: true,
          choices: [
            { value: 'time', label: 'Lack of time' },
            { value: 'energy', label: 'Lack of energy' },
            { value: 'stress', label: 'Stress or emotional factors' },
            { value: 'physical_limitations', label: 'Physical limitations or discomfort' },
            { value: 'resources', label: 'Lack of resources (e.g., equipment, healthy food options)' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(potentialObstacles?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_potential_obstacle',
              attributes: {
                label: 'Please specify the other potential obstacle:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'obstacle_intro',
          attributes: {
            label: "This is big!",
            description: "We've got our goal set and we're almost ready to go. {{field:preferred_name}} we have just a few more questions to ensure we achieve that first step as soon as possible."
          },
        },
        {
          name: 'number',
          id: 'age',
          attributes: {
            label: "What's your age?",
            description: "Your age helps us tailor advice to your life stage and health needs, especially for belly fat loss strategies.",
            required: true,
            min: 18,
            max: 120,
          },
        },
      {
        name: 'custom_phone_block',
        id: 'phone-number',
        attributes: {
          label: 'What\'s your preferred cell phone number?',
          description:
            "We will associate your calls and texts with this number",
          required: true,

        },
      },
      {
        name: 'statement',
        id: 'thank_you',
        attributes: {
          label: "Let's save this awesome progress, {{field:preferred_name}}!",
          description: "The next step is to create your account so we can saves these results and get started. \n\n&nbsp\n\nI'm looking forward to our first chat where we'll dive deeper into your belly fat loss goals, address potential challenges, and craft a personalized plan just for you. Talk to you soon!",
        },
      },
    ],
    theme: {
      font: 'Urbanist, sans-serif',
      backgroundColor: '#F9F7F3',
      buttonsBgColor: '#005f73',
      answersColor: '#001219',
      questionsColor: '#595959',
      buttonsFontColor: '#ffffff',
      buttonsBorderRadius: 4,
      errorsFontColor: '#ae2012',
      errorsBgColor: '#ffe6e6',
      progressBarFillColor: '#005f73',
      progressBarBgColor: '#e9ecef',
    },
    messages: {
      'label.button.ok': 'OK',
      'label.hintText.enter': 'press <strong>Enter ↵</strong>',
      'label.hintText.multipleSelection': 'Choose as many as you like',
      'block.dropdown.placeholder': 'Type or select an option',
      'block.dropdown.noSuggestions': 'No suggestions available',
      'block.shortText.placeholder': 'Type your answer here',
      'block.longText.placeholder': 'Type your answer here',
      'block.number.placeholder': 'Type your answer here',
      'block.email.placeholder': 'Type your email here',
      'block.defaultThankYouScreen.label': `<h3>You did it!</h3>`,
      'label.errorAlert.required': 'This field is required!',
      'label.errorAlert.email': 'Please enter a valid email address.',
      'label.errorAlert.number': 'Please enter a valid number.',
      'label.errorAlert.url': 'Please enter a valid URL.',
      'label.submitBtn': 'Save & Continue',
    },
    settings: {
      disableProgressBar: false,
      disableWheelSwiping: false,
      disableNavigationArrows: false,
      animationDirection: 'vertical',
      showQuestionsNumbers: false,
      showLettersOnAnswers: true,
    },
    customCSS: `
    
    `,
  };

  if (isCreatingAccount) {
    return (
      <AccountCreationForm
        onAccountCreated={handleAccountCreated}
      />
    );
  }

  return (
    <div className={classes.formContainer}>
      <Form formId={1} formObj={formObj} onSubmit={handleSubmit} applyLogic={false} beforeGoingNext={beforeGoingNext} />
    </div>
  );
}

export default HealthyHeartSurveyPage;