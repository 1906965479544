import React from 'react';
import { Container, Paper, Title, Text, Button, Stack, SimpleGrid, Image } from '@mantine/core';
import { IconDownload, IconQrcode, IconPhone } from '@tabler/icons-react';

import qrCodeImage from '@/img/profile/ramona-contact-QR-code.png'
import { getConfig } from '../../config';

const AddRamonaContactPage = () => {
    const config = getConfig();
    console.log(`config: ${config}`)
    console.log(config)
    const ramonaPhone = config.ramonaPhoneNumber;
    const vCardFileName = "Ramona_Contact.vcf";

  const handleVCardDownload = () => {
    const link = document.createElement('a');
    link.href = '/img/profile/RamonaAICoach.vcf';
    link.download = vCardFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container size="sm">
      <Paper shadow="md" p="xl" mt="xl">
        <Stack gap="xl">
          <Title order={2} ta="center">Add Ramona to Your Contacts</Title>
          
          <Text ta="justify">
            A text message from (505) 472-6662 has been sent to the number you provided.
          </Text>
          <Text>Select the attached vCard on the text to save Ramona as a Contact and ensure you never miss a message.</Text>
          <Text ta="justify"> If the message doesn't come through (check your spam/blocked messages), explore the options below.</Text>
          
          <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="lg">

            <Paper withBorder p="md" radius="md">
              <Stack gap="md" align="center">
                <IconQrcode size={48} />
                <Title order={4}>Scan QR Code</Title>
                <Image 
                  src={qrCodeImage} 
                  alt="QR Code for Ramona's contact" 
                  w={120}
                />
                <Text size="sm" ta="center">
                  Scan with your phone's camera
                </Text>
              </Stack>
            </Paper>

            <Paper withBorder p="md" radius="md">
              <Stack gap="md" align="center">
                <IconPhone size={48} />
                <Title order={4}>Click to Call</Title>
                <Button component="a" href={`/first-call`}>
                  Call Ramona
                </Button>
                <Text size="sm" ta="center">
                  Save the number ahead of calling.
                </Text>
              </Stack>
            </Paper>
          </SimpleGrid>
          
          <Text ta="center" size="sm" c="dimmed">
            After adding Ramona to your contacts, you'll be all set to start your health journey!
          </Text>
          
          <Button size="lg" fullWidth component="a" href="/first-call">
            I've Added Ramona to My Contacts
          </Button>
          <Button 
            component="a" 
            href="mailto:support@ramona.care?subject=Support: Add Ramona As Contact"
            style={{ backgroundColor: '#999999' }}
            target='_blank'
          >
            I Need Help Adding a Contact
          </Button>

        </Stack>
      </Paper>
    </Container>
  );
};

export default AddRamonaContactPage;
