import React, { useState, useEffect, forwardRef } from 'react';
import { registerBlockType } from '@quillforms/blocks';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import classnames from 'classnames';
import { useMessages, useBlockTheme } from '@quillforms/renderer-core';
import { css } from 'emotion';
import { BlockAttributes, FormBlocks, FormBlock } from '@quillforms/types';
import tinyColor from 'tinycolor2';

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  attributes?: {
    themeId?: string;
  };
}

interface DisplayProps {
  id: string;
  attributes: CustomBlockAttributes;
  setIsValid: (isValid: boolean) => void;
  setIsAnswered: (isAnswered: boolean) => void;
  setValidationErr: (error: string | null) => void;
  showNextBtn: (show: boolean) => void;
  blockWithError: boolean;
  val: string;
  setVal: (value: string) => void;
  next: () => void;
  // Add these new properties
  block: FormBlock;
  totalBlocks: number;
  currentBlockId: string;
  blocksLength: number;
  isActive: boolean;
  isAnimating: boolean;
  isReviewing: boolean;
  wasRendered: boolean;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
  const theme = useBlockTheme(typeof(props.attributes?.themeId) ==='number' ? props.attributes?.themeId : 0 );
  const answersColor = tinyColor(theme.answersColor);

  return (
    <input
      {...props}
      ref={ref}
      className={classnames(
        css`
          & {
            width: 100%;
            border: none !important;
            border-radius: 0 !important;
            outline: none;
            padding-bottom: 8px;
            background: transparent;
            transition: box-shadow 0.1s ease-out 0s;
            box-shadow: ${answersColor.setAlpha(0.3).toString()}
              0px 1px !important;
          }

          &::placeholder {
            opacity: 0.3;
            color: ${theme.answersColor};
          }

          &:-ms-input-placeholder {
            opacity: 0.3;
            color: ${theme.answersColor};
          }

          &::-ms-input-placeholder {
            opacity: 0.3;
            color: ${theme.answersColor};
          }

          &:focus {
            box-shadow: ${answersColor.setAlpha(1).toString()}
              0px 2px !important;
          }

          color: ${theme.answersColor} !important;
          -webkit-appearance: none;
        `
      )}
    />
  );
});

interface CustomBlockAttributes extends BlockAttributes {
  required: boolean;
  defaultCountry: string;
  setMaxCharacters: boolean;
  maxCharacters: number;
}

interface DisplayProps {
  id: string;
  next: () => void;
  attributes: CustomBlockAttributes;
  setIsValid: (isValid: boolean) => void;
  setIsAnswered: (isAnswered: boolean) => void;
  setValidationErr: (error: string | null) => void;
  showNextBtn: (show: boolean) => void;
  blockWithError: boolean;
  val: string;
  setVal: (value: string) => void;
}

const CustomPhoneBlock: React.FC<DisplayProps> = ({ 
  id, 
  next, 
  attributes, 
  setIsValid, 
  setIsAnswered, 
  setValidationErr, 
  showNextBtn,
  blockWithError, 
  val, 
  setVal,
  block,
  totalBlocks,
  currentBlockId,
  blocksLength,
  isActive,
  isAnimating,
  isReviewing,
  wasRendered
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(val || "");
  const { required, defaultCountry } = attributes;
  const messages = useMessages();
  const theme = useBlockTheme(attributes.themeId);

  useEffect(() => {
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      setIsAnswered(true);
      showNextBtn(true);
      setIsValid(true);
      setValidationErr(null);
    } else {
      setIsAnswered(false);
      showNextBtn(false);
      if (required) {
        setIsValid(false);
        setValidationErr("A valid US phone number is required.");
      }
    }
  }, [phoneNumber, required, setIsAnswered, setIsValid, setValidationErr, showNextBtn]);

  const handleChange = (value: string | undefined) => {
    if (value) {
      setPhoneNumber(value);
      setVal(value);
    }
  };

  return (
    <div className="custom-phone-block">
      <PhoneInput
        defaultCountry="US"
        country="US"
        international={false}
        value={phoneNumber}
        onChange={handleChange}
        placeholder="Enter phone number"
        inputComponent={CustomInput}
      />
    </div>
  );
};

registerBlockType("custom_phone_block", {
  supports: {
    editable: true,
  },
  attributes: {
    required: {
      type: "boolean",
      default: false
    },
    defaultCountry: {
      type: "string",
      default: "US"
    },
    setMaxCharacters: {
      type: "boolean",
      default: true
    },
    maxCharacters: {
      type: "number",
      default: 9
    }
  },
  display: CustomPhoneBlock as React.FC<any>
});