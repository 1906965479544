import { useEffect } from 'react';

declare global {
  interface Window {
    _mtm: any[];
  }
}

export function useMatomo() {
  // Helper to validate string parameters
  const isValidParameter = (param: string | undefined): boolean => {
    return typeof param === 'string' && param.trim().length > 0;
  };

  const trackPageView = (path: string) => {
    if (window._mtm && isValidParameter(path)) {
      window._mtm.push({ 
        'event': 'PageView', 
        'pageUrl': path 
      });
    }
  };

  const trackEvent = (category: string, action: string, name?: string, value?: number) => {
    if (!window._mtm) {
      console.warn('Matomo Tag Manager not initialized');
      return;
    }

    if (!isValidParameter(category) || !isValidParameter(action)) {
      console.error('Matomo event error: category and action must be non-empty strings');
      return;
    }

    window._mtm.push({
      'event': 'Event',
      'eventCategory': category.trim(),
      'eventAction': action.trim(),
      'eventName': name?.trim() || '',
      'eventValue': value || 0
    });
  };

  const trackFormEvent = (category: string, action: string, name?: string, value?: number) => {
    if (!window._mtm) {
      console.warn('Matomo Tag Manager not initialized');
      return;
    }

    if (!isValidParameter(category) || !isValidParameter(action)) {
      console.error('Matomo form event error: category and action must be non-empty strings');
      return;
    }

    const eventData = {
      'event': 'FormEvent',
      'eventCategory': category.trim(),
      'eventAction': action.trim(),
      'eventName': name?.trim() || '',
      'eventValue': value || 0,
      'formBlock': name?.trim() || ''
    };

    // Log the event data for debugging
    console.log('Pushing form event:', eventData);

    window._mtm.push(eventData);
  };

  return { trackPageView, trackEvent, trackFormEvent };
}