import { AppShell } from '@mantine/core';
import { ErrorBoundary, useMedplum } from '@medplum/react';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Router } from './Router';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Loading } from './components/Loading';
import { RegisterPage } from './pages/RegisterPage';
import { SignInPage } from './pages/SignInPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { NewProfilePage } from './pages/NewProfilePage';
import { getConfig } from './config'
import AddRamonaContactPage from './pages/onboarding/AddContact';
import BellyFatSurveyPage from './pages/onboarding/BellyFatIntake';
import { ProfileHeader } from './pages/onboarding/ProfileHeader';
import HeartHealthSurveyPage from './pages/onboarding/HeartHealthIntake';
import { AccountPage } from './pages/account';
import { Profile } from './pages/account/Profile';
import { Provider } from './pages/account/Provider';
import { HealthRecord } from './pages/health-record';
import { Measurement } from './pages/health-record/Measurement';
import { measurementsMeta } from './pages/health-record/Measurement.data';
import { Medications } from './pages/health-record/Medications';
import { Vitals } from './pages/health-record/Vitals';
import { TasksPage } from './pages/TasksPage';
import FirstCall from './pages/onboarding/FirstCall';
import AddContact from './pages/onboarding/AddContact';
import SelectPlan from './pages/onboarding/SelectPlan';
import { useSubscription } from './hooks/subscriptionManagement';
import { FormsPage } from './pages/FormsPage';
import { SignOutPage } from './pages/SignOutPage';

interface RouteConfig {
  path: string;
  element: JSX.Element;
  layout: 'full' | 'simple';
  children?: RouteConfig[];
  public?: boolean;
}


const publicRoutes: RouteConfig[] = [
  { path: '/profile', element: <NewProfilePage />, layout: 'simple', public: true },
  { path: '/profile-heart', element: <HeartHealthSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bf', element: <BellyFatSurveyPage />, layout: 'simple', public: true },
];

const authenticatedRoutes: RouteConfig[] = [
  { path: '/', element: <TasksPage/>, layout: 'full' },
  { path: '/account', element: <AccountPage />, layout: 'full', children: [
    { path: 'profile', element: <Profile/>, layout: 'full' },
    { path: 'provider', element: <Provider />, layout: 'full' },
  ]},
  { path: '/health-record', element: <HealthRecord />, layout: 'full', children: [
    { path: 'vitals', element: <Vitals />, layout: 'full' },
    { path: 'medications', element: <Medications />, layout: 'full' },
    ...Object.keys(measurementsMeta).map((measurementId): RouteConfig => ({
      path: `vitals/${measurementId}`,
      element: <Measurement measurementType={measurementId} />,
      layout: 'full'
    })),
  ]},
  { path: '/first-call', element: <FirstCall />, layout: 'simple' },
  { path: '/add-contact', element: <AddContact />, layout: 'simple' },
  { path: '/profile', element: <NewProfilePage />, layout: 'simple', public: true },
  { path: '/profile-heart', element: <HeartHealthSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bf', element: <BellyFatSurveyPage />, layout: 'simple', public: true },
  { path: 'forms/:formId', element: <FormsPage />, layout: 'simple'},
  { path: 'signout', element: <SignOutPage />, layout: 'simple'}
];

function LayoutWrapper({ children, layout }: { children: React.ReactNode; layout: 'full' | 'simple' }) {
  return (
    <AppShell header={{ height: 80 }}>
      {layout === 'full' ? <Header /> : <ProfileHeader />}
      <AppShell.Main>{children}</AppShell.Main>
      <Footer />
    </AppShell>
  );
}

function RouteWithLayout({ route }: { route: RouteConfig }) {
  return (
    <LayoutWrapper layout={route.layout}>
      {route.element}
      {route.children && <Outlet />}
    </LayoutWrapper>
  );
}

export function App(): JSX.Element | null {
  const medplum = useMedplum();
  const location = useLocation();
  const profile = medplum.getProfile();
  const { isLoading, hasActivePlan, error } = useSubscription();

  if (medplum.isLoading() || hasActivePlan === null) {
    return null;
  }
  
  if (medplum.isLoading()) {
    return null;
  }

  const isAuthenticated = profile;

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="signin" element={<SignInPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="resetpassword" element={<ResetPasswordPage />} />
        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            element={<RouteWithLayout route={route} />}
          />
        ))}
        <Route path="*" element={<Navigate replace to={`/signin?next=${encodeURIComponent(location.pathname + location.search)}`} />} />
      </Routes>
    );
  }
  
  if (isAuthenticated && !hasActivePlan) {
    return <Routes>
      <Route path="signout" element={<SignOutPage />} />
      <Route path="*" element={<RouteWithLayout route={ { path: '*', element: <SelectPlan />, layout: 'simple'} } />} /></Routes>;
  }

  
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Routes>
          {authenticatedRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<RouteWithLayout route={route} />}
            >
              {route.children?.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={<RouteWithLayout route={childRoute} />}
                />
              ))}
            </Route>
          ))}
          <Route path="*" element={<Navigate replace to={'/'} />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
