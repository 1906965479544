import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text, Button, Stack, List, Box, Group, ThemeIcon } from '@mantine/core';
import { IconBarrierBlock, IconHeartHandshake, IconPhone, IconQuestionMark, IconStairs, IconTarget } from '@tabler/icons-react';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { getConfig } from '../../config';
import { Patient } from '@medplum/fhirtypes';

const FirstCallPage = () => {
  const [goal, setGoal] = useState<string | null>(null);
  const [task, setTask] = useState<string | null>(null);
  const [motivations, setMotivations] = useState<string[]>([]);
  const [obstacles, setObstacles] = useState<string[]>([]);
  const medplum = useMedplum();
  const config = getConfig();
  const ramonaPhone = config.ramonaPhoneNumber;
  const profile = useMedplumProfile() as Patient;


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Goal
        const goalResource = await medplum.searchOne('Goal', { patient: 'Patient/' + profile.id });
        if (goalResource && goalResource.description && goalResource.description.coding) {
        const goalCoding = goalResource.description.coding[0];
        if (goalCoding && goalCoding.display) {
            setGoal(goalCoding.display);
        } else {
                    setGoal(null);
                }
        } else {
        setGoal(null);
        }

        // Fetch Task
        console.log(`patient ${profile.id}`)
        const taskResource = await medplum.searchOne('Task', {
          subject: 'Patient/' + profile.id,
          status: 'in-progress'
        });
        console.log('Task pre:', taskResource);
        if (taskResource && taskResource.description) {
            console.log(taskResource);
            setTask(taskResource.description);
          } else {
            setTask(null);
          }

        // Fetch Observations for motivations and challenges
        const observations = await medplum.searchResources('Observation', {
          subject: 'Patient/' + profile.id,
          code: 'primary-motivation,potential-obstacles'
        });

        const motivationObs = observations.filter(obs => obs.code.coding?.[0]?.code === 'primary-motivation');
        const obstacleObs = observations.filter(obs => obs.code.coding?.[0]?.code === 'potential-obstacles');

        if (motivationObs.length > 0) {
          setMotivations(motivationObs.map(obs => obs.valueString || '').filter(Boolean));
        }

        if (obstacleObs.length > 0) {
          setObstacles(obstacleObs.map(obs => obs.valueString || '').filter(Boolean));
        }

        console.log('Goal:', goalResource);
        console.log('Task:', taskResource);
        console.log('Observations:', observations);
        console.log('Motivations:', motivationObs);
        console.log('Obstacles:', obstacleObs);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [medplum]);

  return (
    <Container size="sm">
      <Paper shadow="md" p="xl" mt="xl">
        <Stack gap="xl">
          <Title order={2} ta="center">Make Your First Call with Ramona</Title>
          
          <Text ta="center">
            It's time to start your health journey with Ramona! We've already gathered some information to help guide your conversation:
          </Text>
          
<Box  p="xs" style={{ borderRadius: '8px' }}>
  <Stack gap="md">
    {[
      { title: 'Your main health goal', description: goal, icon: IconTarget },
      { title: 'Your first small step', description: task, icon: IconStairs },
      { title: 'Your motivations', description: motivations.join(', '), icon: IconHeartHandshake },
      { title: 'Obstacles you\'re facing', description: obstacles.join(', '), icon: IconBarrierBlock },
    ].map((item, index) => (
      <Group key={index} align="flex-start" wrap="nowrap">
        <ThemeIcon color="blue" size="lg" radius="lg" style={{ flexShrink: 0 }}>
          <item.icon size={18} />
        </ThemeIcon>
        <Stack gap={5}>
          <Text fw={700} size="lg">{item.title}</Text>
          <Text>{item.description}</Text>
        </Stack>
      </Group>
    ))}
  </Stack>
</Box>
          
          <Text ta="center">
            Ramona will discuss these points with you and help create a personalized plan to achieve your health goals.
          </Text>
          
          <Button 
            size="lg" 
            leftSection={<IconPhone />}
            onClick={() => window.location.href = `tel:${ramonaPhone}`}
          >
            Call Ramona Now at (505) 4-RAMONA
          </Button>
          
          <Text ta="center" size="sm" c="dimmed">
            This call is an important step in your personalized health journey. Ramona is here to support and guide you every step of the way.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};

export default FirstCallPage;
