import { useMantineTheme, AppShell, Container, UnstyledButton, Group, Menu, Avatar, Burger, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMedplumProfile } from "@medplum/react";
import { IconChevronDown, IconUserCircle, IconSettings, IconLogout } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import classes from './ProfileHeader.module.css';

export function ProfileHeader(): JSX.Element {
    const navigate = useNavigate();
    const profile = useMedplumProfile();
    const theme = useMantineTheme();
    const [opened, { toggle }] = useDisclosure(false);
    const [userMenuOpened, setUserMenuOpened] = useState(false);
  
    return (
        <Container>
          <div className={classes.inner}>
            <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
              <Text c="orange.8" tt="uppercase" size="xxl" fw={600} >RAMONA</Text>
            </UnstyledButton>
          </div>
        </Container>
    );
  }
  