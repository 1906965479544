import { BackgroundImage, Box, Grid, Image, SimpleGrid } from '@mantine/core';
import { useMedplum} from '@medplum/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getConfig } from '../config';
import { RegisterForm } from '../components/CustomRegisterForm'
import ramonaLogo from '../img/Ramona-Logo-Medium.webp'


export function RegisterPage({ onRegisterSuccess }: { onRegisterSuccess?: () => void } = {}): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const config = getConfig();
  
  // Get the current URL's search parameters
  const currentSearchParams = new URLSearchParams(window.location.search);

  // Create the new URL with '/profile' as the path and the current search parameters
  const newUrl = '/profile' + (currentSearchParams.toString() ? '?' + currentSearchParams.toString() : '');
  const doNext = () => {
    if (onRegisterSuccess) {
      onRegisterSuccess();
    } else {
      navigate(newUrl);
    }
  };
  
  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => doNext()}
          >
            <Image src={ramonaLogo} w="60%"/>
            <h2 >Let's Create Your Account</h2>
            
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}
