import { 
  Container, Paper, Title, Text, Button, Stack, Group, ThemeIcon, 
  Card, Box, Badge, List 
} from '@mantine/core';
import { Patient, Goal, Task } from '@medplum/fhirtypes';
import { useMedplumProfile, useMedplum } from '@medplum/react';
import { IconLockOpen2, IconMail, IconStarFilled, IconCheck, IconClock, IconChartBar, IconTarget } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const getGoalPresentation = (goalText: string) => {
  // Remove common prefixes to make it more natural
  return goalText.toLowerCase();
};

const getTaskPresentation = (taskText: string) => {
  // Make the task sound more natural and active
  return taskText.toLowerCase();
};



const CelebrationPage = () => {
    const profile = useMedplumProfile() as Patient;
    const medplum = useMedplum();
    const email = profile.telecom?.find(t => t.system === 'email')?.value || '';
    const [goal, setGoal] = useState<Goal>();
    const [nextStep, setNextStep] = useState<Task>();
    
    // Fetch the user's active goal and next step
    useEffect(() => {
      const fetchGoalAndTask = async () => {
        try {
          // Get most recent active goal
          const goals = await medplum.searchResources('Goal', {
            patient: profile.id,
            'lifecycle-status': 'active',
            _count: '1'
          });
          
          if (goals.length > 0) {
            console.log(`golslen: ${goals.length} | ${goals[0].id}`)
            setGoal(goals[0]);
            
            // Get associated task (next step)
            const tasks = await medplum.searchResources('Task', {
              'based-on': `Goal/${goals[0].id}`,
              status: 'requested',
              _count: '1'
            });
            
            if (tasks.length > 0) {
              setNextStep(tasks[0]);
            }
            console.log(`g: ${JSON.stringify(goal)} and ${JSON.stringify(tasks[0])}`)
          }
        } catch (error) {
          console.error('Error fetching goal/task:', error);
        }
      };
      
      fetchGoalAndTask();
    }, [medplum, profile.id]);

    const plans = [
      {
        name: 'Essential',
        price: '$29/month',
        hasTrial: true,
        trialText: '10-day free trial',
        url: 'https://buy.stripe.com/6oEdTZ20W2w8a4M145',
        features: [
          'Personalized Wellness Strategies',
          'Regular Progress Assessments',
          'Custom Goal-Setting and Tracking',
          '24/7 AI-Powered Virtual Coaching',
          '100 SMS Coaching Messages/Month',
          '1 Hour Voice Coaching/Month',
          '1 Daily Wellness Check-in'
        ]
      },
      {
        name: 'Vitals Plus',
        price: '$59/month',
        annualBillingRate: '$708/year billed annually',
        popular: true,
        guaranteeText: '30-day money-back guarantee',
        url: 'https://buy.stripe.com/5kA039axseeQ2CkfZ2',
        features: [
          'Everything in Essential, plus:',
          '4G-Connected Blood Pressure Monitor',
          '200 SMS Coaching Messages/Month',
          '2 Hours Voice Coaching/Month',
          'Up to 3 Daily Wellness Check-ins',
          'Trend Analysis Reports'
        ]
      },
      {
        name: 'Concierge Care',
        price: '$129/mo',
        annualBillingRate: '$1548/year billed annually',
        guaranteeText: '30-day money-back guarantee',
        url: 'https://buy.stripe.com/bIYeY3gVQeeQb8Q149',
        features: [
          'Everything in Vitals Plus, plus:',
          'Unlimited SMS Messages',
          '3 Hours Voice Coaching/Month',
          'Up to 5 Flexible Daily Check-ins',
          'Early Access to New Features',
          
        ]
      }
    ];

    return (
      <Container size="lg">
        <Paper shadow="md" p="xl" mt="xl">
          <Stack gap="xl">
            {/* Personalized Header */}
            <Stack gap="md" ta="center">
              <Title order={2}>
                Congratulations {profile.name?.[0]?.given?.[0] || ''} on taking charge of your health! 🎉
              </Title>
              
              {goal && nextStep && (
                <Box bg="orange.1" p="lg" style={{ borderRadius: '8px' }}>
                  <Stack gap="md">
                    <Group align="center" justify="center">
                      <ThemeIcon size="xl" color="orange" variant="light">
                        <IconTarget size={24} />
                      </ThemeIcon>
                      <Text size="xl" fw={600}>Let's make it happen!</Text>
                    </Group>
                    
                    <Text size="lg" ta="center">
                      You're ready to {getGoalPresentation(goal.description.text ||'')}, 
                      and we'll start by helping you {getTaskPresentation(nextStep.description || '')}.
                    </Text>
                    
                    <Text size="md" c="dimmed" ta="center">
                      Choose your level of support below to begin your journey
                    </Text>
                  </Stack>
                </Box>
              )}
            </Stack>

            {/* Research-Backed Benefits */}
            <Group grow gap="md">
              <Card withBorder p="md">
                <Group gap="sm">
                  <ThemeIcon size="lg" color="orange">
                    <IconClock size={20} />
                  </ThemeIcon>
                  <Text fw={600}>24/7 Support When You Need It</Text>
                </Group>
                <Text size="sm" mt="xs" c="dimmed">
                  Research shows accessible digital health support leads to better outcomes
                </Text>
              </Card>

              <Card withBorder p="md">
                <Group gap="sm">
                  <ThemeIcon size="lg" color="orange">
                    <IconChartBar size={20} />
                  </ThemeIcon>
                  <Text fw={600}>Personalized to Your Journey</Text>
                </Group>
                <Text size="sm" mt="xs" c="dimmed">
                  Tailored digital health programs show significantly better results
                </Text>
              </Card>
            </Group>

            {/* How Plans Work */}
            <Card withBorder p="md">
              <Title order={3} ta="center" mb="md">How our plans work</Title>
              
              <Stack gap="md">
                {[
                  { 
                    title: 'Essential Plan',
                    description: 'Start your 10-day free trial. No charge until day 10, cancel anytime before then.'
                  },
                  { 
                    title: 'Device Plans (Vitals Plus, Concierge Care)', 
                    description: 'Your 4G-connected blood pressure cuff ships immediately. 30-day satisfaction guarantee with full refund (minus $90 device cost). The cuff will continue to operate after you cancel the service.'
                  },
                ].map((item, index) => (
                  <Group key={index} align="flex-start" wrap="nowrap">
                    <ThemeIcon color="teal" size="md" radius="xl">
                      <IconCheck size={20} />
                    </ThemeIcon>
                    <Stack gap={5}>
                      <Text fw={700} size="lg">{item.title}</Text>
                      <Text>{item.description}</Text>
                    </Stack>
                  </Group>
                ))}
              </Stack>
            </Card>

            <Title order={3} ta="center">Select your plan</Title>
            
            {/* Plan Cards - Same as before */}
            <Group justify="center" gap="xl" align="stretch">
            {plans.map((plan) => (
                <Card 
                  key={plan.name} 
                  shadow="sm" 
                  padding="lg" 
                  radius="md" 
                  withBorder 
                  style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    width: '300px',
                    border: plan.popular ? '2px solid #228be6' : undefined 
                  }}
                >
                  <Stack gap="md" mb="xl">
                    <Group justify="apart" align="center">
                      <Title order={4} fw={600}>{plan.name}</Title>
                      {plan.popular && (
                        <Badge color="blue">MOST POPULAR</Badge>
                      )}
                    </Group>
                    
                    <Stack gap={4}>
                      <Text size="xl" fw={700}>
                        {plan.price}
                      </Text>
                      {plan.annualBillingRate && (
                        <Text size="sm" c="dimmed">
                          {plan.annualBillingRate}
                        </Text>
                      )}
                      {(plan.trialText || plan.guaranteeText) && (
                        <Text size="sm" c="blue" fw={500}>
                          {plan.trialText || plan.guaranteeText}*
                        </Text>
                      )}
                    </Stack>

                    <List
                      spacing="sm"
                      size="sm"
                      icon={
                        <ThemeIcon color="teal" size={24} radius="xl">
                          <IconCheck size="1rem" />
                        </ThemeIcon>
                      }
                    >
                      {plan.features.map((feature, index) => (
                        <List.Item key={index}>
                          <Text size="sm">
                            {feature}
                          </Text>
                        </List.Item>
                      ))}
                    </List>
                  </Stack>

                  <Button 
                    color="blue" 
                    fullWidth 
                    component={Link} 
                    to={plan.url+'?prefilled_email='+email}
                    variant={plan.popular ? 'filled' : 'light'}
                    style={{ marginTop: 'auto' }}
                  >
                    Select {plan.name}
                  </Button>
                </Card>
              ))}
            </Group>

            {/* Footer Text */}
            <Text ta="center" size="sm" c="dimmed">
              Essential plan includes 10-day free trial. Vitals Plus and Concierge Care plans include 30-day satisfaction guarantee. 
              If you're not satisfied, receive a full refund minus $90 device cost. See our <Text component="a" inherit c="dimmed" href="https://www.ramona.care/refund-policy/" target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>Refund Policy</Text> for complete details.
            </Text>
          </Stack>
        </Paper>
      </Container>
    );
};

export default CelebrationPage;
