export interface LogoProps {
    readonly size: number;
    readonly fill?: string;
  }
  
  export function LogoSquare(props: LogoProps): JSX.Element {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: props.size, height: props.size }}>
        <title>Ramona Logo</title>
        <path d="M34 22 V78 H40 V57 H60 L68 78 H75 L66 54 Q74 47 74 38 Q74 22 63 22 Z M40 28 H63 Q68 28 68 38 Q68 47 60 47 H40 Z" fill={props.fill ?? "#EC7545"}/>
      </svg>
    );
  }
  