import { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  Group,
  Radio,
  Select,
  Stack,
  Text,
  Textarea,
  Paper,
  Alert,
  Timeline,
  Checkbox,
  Loader
} from '@mantine/core';
import { IconThumbUp, IconThumbDown, IconMessage, IconPhone, IconClipboardList } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { formatDistanceToNow } from 'date-fns';
import { Communication, Task, Reference, Patient, Encounter } from '@medplum/fhirtypes';

interface FeedbackFormProps {
  onSubmit?: () => void;
}

type InteractionType = 'message' | 'call' | 'task';

interface Interaction {
  id: string;
  type: InteractionType;
  description: string;
  timestamp: string;
  resourceType: 'Communication' | 'Task' | 'Encounter';
}

interface FeedbackState {
  rating: string;
  category: string;
  details: string;
  selectedInteractions: Set<string>;
}

export function FeedbackForm({ onSubmit }: FeedbackFormProps): JSX.Element {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const patientId = profile?.id;
  if (!patientId) {
    throw new Error('Patient ID not found');
  }
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [recentInteractions, setRecentInteractions] = useState<Interaction[]>([]);
  const [feedback, setFeedback] = useState<FeedbackState>({
    rating: '',
    category: '',
    details: '',
    selectedInteractions: new Set<string>()
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const categories = [
    { value: 'voice', label: 'Voice/Audio Quality' },
    { value: 'understanding', label: 'Understanding My Needs' },
    { value: 'usefulness', label: 'Usefulness of Advice' },
    { value: 'technical', label: 'Technical Issues' },
    { value: 'other', label: 'Other' }
  ];

  useEffect(() => {
    const fetchRecentInteractions = async () => {
      try {

        // Fetch recent encounters (calls)
        const encounters = await medplum.searchResources('Encounter', {
          patient: `Patient/${patientId}`,
          _sort: '-date',
          _count: '5'
        });

        // Fetch recent communications (messages)
        const communications = await medplum.searchResources('Communication', {
          subject: `Patient/${patientId}`,
          _sort: '-sent',
          'sender:not': `Patient/${patientId}`,
          _count: '5'
        });

        // Fetch recent tasks
        const tasks = await medplum.searchResources('Task', {
          subject: `Patient/${patientId}`,
          _sort: '-authored-on',
          _count: '5'
        });

       // Process encounters (calls)
        const encounterInteractions: Interaction[] = encounters.map(encounter => {
            return {
            id: encounter.id ?? '',
            type: 'call',
            description: encounter.reasonCode?.[0]?.text ?? 'Phone Call',
            timestamp: encounter.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
            resourceType: 'Encounter'
            };
        });
        
        // Process communications (messages)
        const communicationInteractions: Interaction[] = communications
            .filter(comm => !comm.encounter)
            .map(comm => {
            return {
                id: comm.id ?? '',
                type: 'message',
                description: comm.payload?.[0]?.contentString ?? 'Message',
                timestamp: comm.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
                resourceType: 'Communication'
            };
            });
        
        // Process tasks
        const taskInteractions: Interaction[] = tasks.map(task => {
            return {
            id: task.id ?? '',
            type: 'task',
            description: task.description ?? 'Task',
            timestamp: task.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
            resourceType: 'Task'
            };
        });

        // Combine, sort, and limit interactions
        const allInteractions = [...encounterInteractions, ...communicationInteractions, ...taskInteractions]
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          .slice(0, 5);

        setRecentInteractions(allInteractions);
      } catch (error) {
        console.error('Failed to fetch recent interactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentInteractions();
  }, [medplum]);

  const handleInteractionToggle = (interactionId: string) => {
    setFeedback(prev => {
      const newSelected = new Set(prev.selectedInteractions);
      if (newSelected.has(interactionId)) {
        newSelected.delete(interactionId);
      } else {
        newSelected.add(interactionId);
      }
      return {
        ...prev,
        selectedInteractions: newSelected
      };
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // Create references to selected interactions
      const selectedIds = Array.from(feedback.selectedInteractions);
      const aboutReferences: Reference[] = selectedIds.map(id => {
        const interaction = recentInteractions.find(i => i.id === id);
        if (!interaction) return { reference: '' };
        return {
          reference: `${interaction.resourceType}/${id}`
        };
      }).filter(ref => ref.reference !== '');
  
      const communicationResource: Communication = {
        resourceType: 'Communication',
        status: 'completed',
        category: [{
          coding: [{
            system: 'http://terminology.hl7.org/CodeSystem/communication-category',
            code: 'feedback',
            display: 'User Feedback'
          }]
        }],
        subject: { reference: `Patient/${patientId}` },
        sent: new Date().toISOString(),
        payload: [
          {
            contentString: JSON.stringify({
              rating: feedback.rating,
              category: feedback.category,
              details: feedback.details,
              interactionIds: selectedIds
            })
          }
        ],
        about: aboutReferences
      };
  
      await medplum.createResource(communicationResource);
  
      setSubmitted(true);
      if (onSubmit) {
        onSubmit();
      }
  
      setTimeout(() => {
        setSubmitted(false);
        setStep(0);
        setFeedback({
          rating: '',
          category: '',
          details: '',
          selectedInteractions: new Set<string>()
        });
      }, 3000);
  
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Update the submit button in the final step
  {step === 3 && (
    <Stack>
      <Text fw={500}>Additional details (optional)</Text>
      <Textarea
        value={feedback.details}
        onChange={(e) => setFeedback(prev => ({ ...prev, details: e.target.value }))}
        placeholder="Tell us more about your experience..."
        minRows={3}
      />
      <Group justify="flex-end">
        <Button 
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting || !feedback.category} // Also disable if no category selected
        >
          Submit Feedback
        </Button>
      </Group>
    </Stack>
  )}

  const getInteractionIcon = (type: InteractionType) => {
    switch (type) {
      case 'call':
        return <IconPhone size={16} />;
      case 'message':
        return <IconMessage size={16} />;
      case 'task':
        return <IconClipboardList size={16} />;
    }
  };

  const getInteractionDescription = (interaction: Interaction) => {
    switch (interaction.type) {
      case 'call':
        return `Phone Call: ${interaction.description}`;
      case 'message':
        return `Message: ${interaction.description}`;
      case 'task':
        return `Task: ${interaction.description}`;
    }
  };


  return (
    <Paper shadow="xs" p="md" withBorder>
      {step === 0 && (
        <Stack>
          <Text fw={500} ta="center">How was your most recent experience?</Text>
          <Group justify="center" gap="xl">
            <Button
              variant="subtle"
              leftSection={<IconThumbUp />}
              onClick={() => {
                setFeedback(prev => ({ ...prev, rating: 'positive' }));
                setStep(1);
              }}
            >
              Helpful
            </Button>
            <Button
              variant="subtle"
              leftSection={<IconThumbDown />}
              onClick={() => {
                setFeedback(prev => ({ ...prev, rating: 'negative' }));
                setStep(1);
              }}
            >
              Not Helpful
            </Button>
          </Group>
        </Stack>
      )}

    {step === 1 && (
        <Stack>
          <Text fw={500}>Which interactions are you providing feedback about?</Text>
          {loading ? (
            <Group justify="center">
              <Loader size="sm" />
            </Group>
          ) : (
            <Timeline active={-1} bulletSize={24}>
              {recentInteractions.map((interaction) => (
                <Timeline.Item
                  key={interaction.id}
                  bullet={getInteractionIcon(interaction.type)}
                  title={
                    <Group gap="xs">
                      <Checkbox
                        label={getInteractionDescription(interaction)}
                        checked={feedback.selectedInteractions.has(interaction.id)}
                        onChange={() => handleInteractionToggle(interaction.id)}
                      />
                    </Group>
                  }
                >
                  <Text size="xs" c="dimmed">
                    {formatDistanceToNow(new Date(interaction.timestamp))} ago
                  </Text>
                </Timeline.Item>
              ))}
            </Timeline>
          )}
          <Group justify="flex-end">
            <Button 
              onClick={() => setStep(2)}
              disabled={feedback.selectedInteractions.size === 0}
            >
              Next
            </Button>
          </Group>
        </Stack>
      )}

      {step === 2 && (
        <Stack>
          <Text fw={500}>What aspect would you like to comment on?</Text>
          <Select
            data={categories}
            placeholder="Select a category"
            onChange={(value) => {
              setFeedback(prev => ({ ...prev, category: value || '' }));
              setStep(3);
            }}
          />
        </Stack>
      )}

      {step === 3 && (
        <Stack>
          <Text fw={500}>Additional details (optional)</Text>
          <Textarea
            value={feedback.details}
            onChange={(e) => setFeedback(prev => ({ ...prev, details: e.target.value }))}
            placeholder="Tell us more about your experience..."
            minRows={3}
          />
          <Group justify="flex-end">
            <Button onClick={handleSubmit}>
              Submit Feedback
            </Button>
          </Group>
        </Stack>
      )}
    </Paper>
  );
}