import { Anchor, Button, Center, Checkbox, Group, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { LoginAuthenticationResponse, normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import { MedplumLink, useMedplum } from '@medplum/react';
import { ReactNode, useEffect, useState } from 'react';
import { Form } from '@medplum/react';
import { OperationOutcomeAlert } from '@medplum/react';
import { getErrorsForInput, getIssuesForExpression } from '@medplum/react';
import { getRecaptcha, initRecaptcha } from '@medplum/react';

export interface NewUserFormProps {
  readonly projectId: string;
  readonly clientId?: string;
  readonly googleClientId?: string;
  readonly recaptchaSiteKey?: string;
  readonly children?: ReactNode;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
}

export function NewUserForm(props: NewUserFormProps): JSX.Element {
  const recaptchaSiteKey = props.recaptchaSiteKey;
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [termsAccepted, setTermsAccepted] = useState<boolean | null>(null);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean | null>(null);
  const [smsAccepted, setSMSAccepted] = useState<boolean | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const issues = getIssuesForExpression(outcome, undefined);
  


  useEffect(() => {
    if (recaptchaSiteKey) {
      initRecaptcha(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey]);

  const handleSubmit = async (formData: Record<string, string>) => {
    setSubmitted(true);
    setIsCreating(true);

    if (!termsAccepted || !privacyAccepted || !smsAccepted) {
      setOutcome({
        resourceType: 'OperationOutcome',
        issue: [
          {
            severity: 'error',
            code: 'invalid',
            details: {
              text: 'You must accept all of the policies to continue.'
            }
          }
        ]
      });
      setIsCreating(false);
      return;
    }

    try {
      let recaptchaToken = '';
      if (recaptchaSiteKey) {
        recaptchaToken = await getRecaptcha(recaptchaSiteKey);
      }
      props.handleAuthResponse(
        await medplum.startNewUser({
          projectId: props.projectId,
          clientId: props.clientId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          remember: formData.remember === 'true',
          recaptchaSiteKey,
          recaptchaToken,
        })
      );
    } catch (err) {
      setOutcome(normalizeOperationOutcome(err));
      setIsCreating(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Center style={{ flexDirection: 'column' }}>{props.children}</Center>
      <OperationOutcomeAlert issues={issues} />
      <Stack gap="xl" pb={"xl"}>
        <TextInput
          name="firstName"
          type="text"
          label="First name"
          placeholder="First name"
          required={true}
          autoFocus={true}
          error={getErrorsForInput(outcome, 'firstName')}
        />
        <TextInput
          name="lastName"
          type="text"
          label="Last name"
          placeholder="Last name"
          required={true}
          error={getErrorsForInput(outcome, 'lastName')}
        />
        <TextInput
          name="email"
          type="email"
          label="Email"
          placeholder="name@domain.com"
          required={true}
          error={getErrorsForInput(outcome, 'email')}
        />
        <PasswordInput
          name="password"
          label="Password"
          autoComplete="off"
          required={true}
          error={getErrorsForInput(outcome, 'password')}
        />
        </Stack>
        <Stack gap="sm">
        <Checkbox
          checked={termsAccepted ?? false}
          onChange={(event) => setTermsAccepted(event.currentTarget.checked)}
          label={
            <>
              I accept the{' '}
              <Anchor href="https://www.ramona.care/terms/" target="_blank" inherit>
                terms and conditions
              </Anchor>
              *
            </>
          }
          error={submitted && !termsAccepted ? 'You must accept the terms and conditions' : null}
        />
        <Checkbox
          checked={privacyAccepted ?? false}
          onChange={(event) => setPrivacyAccepted(event.currentTarget.checked)}
          label={
            <>
              I accept the{' '}
              <Anchor href="https://www.ramona.care/privacy/" target="_blank" inherit>
                privacy policy
              </Anchor>
              *
            </>
          }
          error={submitted && !privacyAccepted ? 'You must accept the privacy policy' : null}
        />
        <Checkbox
          checked={smsAccepted ?? false}
          onChange={(event) => setSMSAccepted(event.currentTarget.checked)}
          label={
            <>
              I accept the{' '}
              <Anchor href="https://www.ramona.care/sms-email-consent/" target="_blank" inherit>
                text and email policy
              </Anchor>
              *
            </>
          }
          error={submitted && !smsAccepted ? 'You must accept the Text and Email Consent to proceed' : null}
        />
        </Stack>
        
      
      <Group justify="space-between" mt="xl" wrap="nowrap">
        <MedplumLink to='/signin'>Sign in</MedplumLink>
        <Button type="submit" size='lg' disabled={isCreating}>{isCreating ? 'Creating...' : 'Create account'}</Button>
      </Group>
      <Text c="dimmed" size="sm" mt="xl">By checking the Text and Email box, you agree to receive daily health coaching messages from Ramona. Msg & data rates may apply. For help text HELP to 505-4RAMONA or email support@ramona.care. Text STOP to cancel messaging anytime.</Text>
    </Form>
  );
}