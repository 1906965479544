import { useMedplum, useResource } from '@medplum/react';
import { Account, Extension, Reference, Resource } from '@medplum/fhirtypes';
import { useEffect, useState, useCallback, useRef } from 'react';

interface SubscriptionFeatures {
  smsMessagesPerMonth: number;
  voiceMinutesPerMonth: number;
  maxDailyCheckins: number;
  includedDevices: string[];
  hasAdvancedAnalytics: boolean;
  hasEarlyAccess: boolean;
}

interface SubscriptionDetails {
  planId: string;
  status: string;
  startDate: string;
  features: SubscriptionFeatures;
}

// Cache configuration
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes
const subscriptionCache = new Map<string, {
  data: SubscriptionDetails | null;
  timestamp: number;
  accountId: string;
}>();

// Polling intervals for different states
const POLLING_INTERVALS = {
  WAITING_FOR_SUBSCRIPTION: 5 * 1000,    // 5 seconds when waiting for subscription
  ACTIVE_SUBSCRIPTION: 60 * 60 * 1000,   // 60 minutes when subscription is active
  UNAUTHENTICATED: null,                 // Don't poll when unauthenticated
} as const;

export function useSubscription() {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState<SubscriptionDetails | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);
  
  const pollIntervalRef = useRef<number | null>(null);
  const account = useResource<Account>(accountId ? { reference: `Account/${accountId}` } : undefined);

  const fetchSubscription = useCallback(async (forceFetch = false) => {
    if (!profile?.id) {
      setIsLoading(false);
      return;
    }

    try {
      // Check cache first if not forcing a fetch
      if (!forceFetch) {
        const cached = subscriptionCache.get(profile.id);
        const now = Date.now();
        
        if (cached && 
            (now - cached.timestamp < CACHE_TTL) && 
            (!accountId || cached.accountId === accountId)) {
          setSubscription(cached.data);
          setIsLoading(false);
          return;
        }
      }

      // Fixed search query format
      const searchResult = await medplum.searchOne('Account', {
        subject: `Patient/${profile.id}`,
        'type': 'http://terminology.hl7.org/CodeSystem/v3-ActCode|PBILLACCT',
        status: 'active'
      });

      const accountResource = searchResult as Account | undefined;
      
      if (!accountResource) {
        setSubscription(null);
        updateCache(profile.id, null, '');
        setIsLoading(false);
        return;
      }

      setAccountId(accountResource.id || null);

      const subscriptionExt = findSubscriptionExtension(accountResource);
      const details = subscriptionExt ? parseSubscriptionDetails(subscriptionExt) : null;
      
      setSubscription(details);
      updateCache(profile.id, details, accountResource.id || '');
      
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error'));
      console.error('Error fetching subscription:', err);
    } finally {
      setIsLoading(false);
    }
  }, [medplum, profile?.id, accountId]);

  // Function to update polling interval based on current state
  const updatePollingInterval = useCallback(() => {
    // Clear existing interval
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }

    // Determine appropriate polling interval
    let pollingInterval: number | null = null;

    if (!profile) {
      pollingInterval = POLLING_INTERVALS.UNAUTHENTICATED;
    } else if (!subscription?.status || subscription.status !== 'active') {
      pollingInterval = POLLING_INTERVALS.WAITING_FOR_SUBSCRIPTION;
    } else {
      pollingInterval = POLLING_INTERVALS.ACTIVE_SUBSCRIPTION;
    }

    // Set up new interval if needed
    if (pollingInterval) {
      pollIntervalRef.current = window.setInterval(() => {
        fetchSubscription(true);
      }, pollingInterval);
    }
  }, [profile, subscription?.status, fetchSubscription]);

  // Initial fetch and cache setup
  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  // Update polling interval whenever relevant state changes
  useEffect(() => {
    updatePollingInterval();
    
    // Cleanup function
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [updatePollingInterval]);

  // React to Account resource updates from Medplum
  useEffect(() => {
    if (account && 'resourceType' in account && account.resourceType === 'Account') {
      const subscriptionExt = findSubscriptionExtension(account);
      const details = subscriptionExt ? parseSubscriptionDetails(subscriptionExt) : null;
      setSubscription(details);
      if (profile?.id) {
        updateCache(profile.id, details, account.id || '');
      }
    }
  }, [account, profile?.id]);

  return {
    isLoading,
    subscription,
    error,
    hasActivePlan: subscription?.status === 'active',
    refresh: () => fetchSubscription(true)
  };
}

// Helper functions remain the same
function updateCache(profileId: string, data: SubscriptionDetails | null, accountId: string) {
  subscriptionCache.set(profileId, {
    data,
    timestamp: Date.now(),
    accountId
  });
}

function findSubscriptionExtension(account: Account): Extension | undefined {
  return account.extension?.find(
    ext => ext.url === 'https://ramona.care/subscription-details'
  );
}

function parseSubscriptionDetails(extension: Extension): SubscriptionDetails | null {
  const subExtensions = extension.extension;
  if (!subExtensions) return null;

  const getValue = (url: string) => {
    const ext = subExtensions.find(e => e.url === url);
    return ext?.valueString || ext?.valueDateTime || '';
  };

  const features = getValue('features');
  
  return {
    planId: getValue('planId'),
    status: getValue('status'),
    startDate: getValue('startDate'),
    features: features ? JSON.parse(features) : null
  };
}